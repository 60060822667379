<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Clientes" :dense="true" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: loadingDetail, text: instance.name }" :hiddenTab="true" :addPadding="true" :scroll="scroll">
    <template v-slot:main>
      <v-btn color="primary" @click="handlerEdit" :disabled="loadingDetail"><v-icon left>mdi-pencil</v-icon>Editar cliente</v-btn>
    </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="loadingDetail" />
      <div class="d-flex" v-else>
      <!-- detail card -->
        <div class="mr-6" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-customer.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 280px;">
                {{instance.name}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">RUT</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.taxpayer.tax_id}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Resumen del cliente</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <v-card-text class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Actividad económica</v-list-item-title>
                    <v-list-item-subtitle class="body-1 d-inline-block text-truncate" style="max-width: 100%;">{{(instance || {}).business_activity}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Email de emisión</v-list-item-title>
                    <v-list-item-subtitle class="body-1 d-inline-block text-truncate" style="max-width: 100%;">
                      <template v-if="instance.email">{{instance.email}}</template>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Dirección</v-list-item-title>
                    <v-list-item-subtitle class="body-1 d-inline-block text-truncate" style="max-width: 100%;">
                      <template v-if="instance.address">{{instance.address}}</template>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">{{$t('generals.Comuna')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <template v-if="editable.state">{{editable.state}}</template>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <!-- <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Teléfono</v-list-item-title>
                      <v-list-item-subtitle class="body-1">
                      <template v-if="instance.phone">{{instance.phone}}</template>
                      <span class="body-2 text--disabled font-italic" v-else>Sin número de teléfono</span>
                      </v-list-item-subtitle>
                  </v-list-item> -->
                  <!-- <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">ID</v-list-item-title>
                      <v-list-item-subtitle class="body-1">
                      <v-tooltip color="bgSearch" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                          <v-btn @click="toClipboard(instance.id)" text v-on="on" :ripple="false" style="justify-content: left;">{{instance.id}}</v-btn>
                          </template>
                          <span>Copiar</span>
                      </v-tooltip>
                      </v-list-item-subtitle>
                  </v-list-item> -->
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2">Actualizado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.updated | dateTime}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </div>
      <!-- end summary -->

      <!-- panels -->
      <div class="pb-5" style="flex:1; min-width: 200px;">
        <!-- contacts -->
        <v-expansion-panels v-model="contactPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-5 py-4">
              <v-row no-gutters align="center">
                <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon class="mr-2" small color="secondary">mdi-email</v-icon>Contactos</span>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-divider />
              <v-skeleton-loader class="rounded-md" v-if="contactsLoading" transition="fade-transition" type="table-tbody" />
              <template v-else>
                <v-empty-state class="mt-5 pb-2" v-if="!contactsList.length" type="customer-econ" id="documentos" customClass="mt-5" :isFree="true" title="Aún no tienes contactos" :height="169" />
                <v-simple-table class="table-not-rounded no-hover pt-0 pb-1 px-2" v-else dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left secondary--text font-weight-medium" style="min-width: 280px;"><v-icon class="ml-n2" small left color="secondary">mdi-account</v-icon>Nombre</th>
                        <th class="text-left secondary--text font-weight-medium" style="min-width: 180px;"><v-icon small left color="secondary">mdi-email</v-icon>Email</th>
                        <th class="text-right secondary--text font-weight-medium"><v-icon small left color="secondary">mdi-phone</v-icon>Teléfono</th>
                        <th style="min-width: 80px;"/>
                      </tr>
                      <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                    </thead>
                    <tbody>
                      <tr v-for="contact in contactsList" :key="contact.id">
                        <td class="body-2">
                          <span class="d-inline-block text-truncate ml-n1" v-if="contact.name" style="max-width: 100%;">{{contact.name}}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-left body-2" style="min-width: 180px;">
                          <span class="d-inline-block text-truncate" style="max-width: 100%;">{{contact.email}}</span>
                        </td>
                        <td class="text-right body-2">
                          <span v-if="contact.phone">{{contact.phone}}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right body-2">
                          <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                            </template>
                            <v-list class="pa-0">
                              <v-list-item class="px-3" @click="handlerDelete(contact.id, 'contacts')" :ripple="false">
                                <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-delete</v-icon>Eliminar contacto</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end contacts -->

        <!-- address -->
        <v-expansion-panels class="mt-5" v-model="addressPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-5 py-4">
              <v-row no-gutters align="center">
                <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon class="mr-2" small color="secondary">mdi-directions</v-icon>Direcciones</span>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 no-hover">
              <v-divider />
              <v-skeleton-loader class="rounded-md" v-if="additionalAddressLoading" transition="fade-transition" type="table-tbody" />
              <template v-else>
                <v-empty-state class="mt-7 pb-2" v-if="!extra_addressesList.length" type="customer-address" id="documentos" customClass="mt-5" :isFree="true" title="Aún no tienes direcciones" :height="169" />
                <v-simple-table class="table-not-rounded no-hover pt-0 pb-1 px-2" v-else dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left secondary--text font-weight-medium" style="min-width: 280px;">Dirección</th>
                        <th class="text-left secondary--text font-weight-medium" style="min-width: 180px;">{{$t('generals.Comuna')}}</th>
                        <th style="min-width: 80px;"/>
                      </tr>
                      <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                    </thead>
                    <tbody>
                      <tr v-for="address in extra_addressesList" :key="address.id">
                        <td class="body-2">
                          <span class="d-inline-block text-truncate ml-n1" style="max-width: 100%;">{{address.address}}</span>
                        </td>
                        <td class="text-left body-2" style="min-width: 180px;">
                          <span class="d-inline-block text-truncate" v-if="address.state" style="max-width: 100%;">{{nameStateList(address.state) || address.state }}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right body-2">
                          <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                            </template>
                            <v-list class="pa-0">
                              <v-list-item class="px-3" @click="handlerDelete(address.id, 'extra_addresses')" :ripple="false">
                                <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-delete</v-icon>Eliminar dirección</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end address -->

        <!-- activity -->
        <v-expansion-panels class="mt-5" v-model="activityPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-5 py-4">
              <v-row no-gutters align="center" justify="space-between">
                <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon class="mr-2" small color="secondary">mdi-hand-coin</v-icon>Actividad económica</span>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-divider />
              <v-skeleton-loader class="rounded-md" v-if="contactsLoading" transition="fade-transition" type="table-tbody" />
              <template v-else>
                <v-empty-state class="mt-7 pb-2" v-if="!business_activitiesList.length" type="customer-contact" id="documentos" customClass="mt-5" :isFree="true" title="Aún no tienes actividad económica" :height="169" />
                <v-simple-table class="table-not-rounded no-hover pt-2 pb-1 px-2" v-else dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="activity in business_activitiesList" :key="activity.id">
                        <td class="body-2">
                          <span class="d-inline-block text-truncate ml-n1" style="max-width: 100%;">{{activity.description }}</span>
                        </td>
                        <td class="text-right body-2">
                          <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                            </template>
                            <v-list class="pa-0">
                              <v-list-item class="px-3" @click="handlerDelete(activity.id, 'business_activities')" :ripple="false">
                                <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-delete</v-icon>Eliminar actividad</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end activity -->

        <!-- related documents -->
        <v-expansion-panels class="mt-5" v-model="panelRelatedDocuments">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-5 py-4">
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="pa-0">
                  <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos relacionados</span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-divider v-if="documentsList.length > 0" />
              <documents-list :loading="loading" :show-pagination="false" />
              <template v-if="documentsList.length >= 6">
                <v-divider />
                <v-row class="px-4 py-4" align="center">
                  <v-btn class="ml-5 body-1" :to="{name: 'DocumentsList', query: { customer: instance.id, name: instance.name || instance.id } }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                </v-row>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end related documents -->

        <!-- comments --
        <v-expansion-panels class="mt-5" v-model="panelComments">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-4 py-3">
              <v-row no-gutters align="center" justify="space-between">
                <v-col>
                  <span class="subtitle-2 secondary--text font-weight-bold">Comentarios</span>
                  </v-col>
                  <v-col class="text-right mr-2">
                  <v-icon size="25" left :color="comments ? 'primary' : 'secondary'">mdi-message-badge</v-icon>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-container>
                <v-row class="px-5 pb-5" align="start">
                  <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                  <v-col class="py-0 pr-0">
                    <v-textarea placeholder="Escribe un comentario..." auto-grow outlined flat hide-details />
                  </v-col>
                </v-row>
              <v-list three-line v-if="comments">
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold secondary--text">Juan Pérez</v-list-item-title>
                    <span class="d-block secondary--text caption">Hace 3 horas</span>
                    <v-list-item-subtitle class="secondary--text mt-1">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!- end comments -->
      </div>
      <!-- end panels -->
      </div>
    </v-col>

    <!--modal update customer-->
    <v-dialog v-model="dialogCustomer" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Editar cliente</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogCustomer=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <!-- <v-skeleton-loader v-if="$store.state.customers.retrieveLoader" class="document-skeleton rounded-md" v-bind="attrs" type="list-item-three-line" /> -->
          <!-- <template v-else> -->
            <v-row class="px-5" no-gutters>
              <v-col cols="3" class="pa-0">
                <span class="fontBody--text body-1">{{$t('generals.RUT')}}</span>
                <v-text-field v-model.trim="$v.editable.taxpayer.tax_id.$model" :error="$v.editable.taxpayer.tax_id.$error" readonly disabled v-dni="editable.country" outlined single-line dense hide-details />
              </v-col>
              <v-col class="pa-0 ml-3">
                <span class="fontBody--text body-1">Nombre de la organización</span>
                <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-col>
            </v-row>
            <v-row class="px-5 py-3" no-gutters>
              <v-col cols="5" class="pa-0">
                <span class="fontBody--text body-1">Actividad económica</span>
                <v-text-field v-model.trim="$v.editable.business_activity.$model" :error="$v.editable.business_activity.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-col>
              <v-col class="pa-0 ml-3">
                <span class="fontBody--text body-1">Correo de emisión</span>
                <v-text-field v-model.trim="$v.editable.email.$model" :error="$v.editable.email.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-col>
            </v-row>
            <v-row class="px-5 pb-0" no-gutters>
              <v-col cols="8" class="pa-0">
                <span class="fontBody--text body-1">Dirección</span>
                <v-text-field v-model.trim="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-col>
              <v-col class="pa-0 ml-3">
                <span class="fontBody--text body-1">{{$t('generals.Comuna')}}</span>
                <v-autocomplete v-model="$v.editable.state.$model" :error="$v.editable.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates'].map(({name}) => name)" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`" />
              </v-col>
            </v-row>
            <v-divider class="my-4" />

            <div class="px-5 pt-1">
              <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
                Actividad económica adicional
                <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
              </v-toolbar-title>

              <div v-for="(d, i) in additionalTaxSettings" :key="i">
                <v-row class="py-1" align="center" no-gutters>
                  <v-col cols="8">
                    <span class="fontBody--text body-1">Actividad económica</span>
                    <v-text-field v-model.trim="d.description" outlined required single-line dense maxlength="64" hide-details />
                  </v-col>
                  <v-col>
                    <v-btn class="mt-4" v-if="i >= 1 || additionalTaxSettings.length > 1" @click="handlerDelete(d.id, 'business_activities', 'modal'), additionalTaxSettings.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-btn class="mt-1" v-if="additionalTaxSettings.length < 10" @click.prevent="addRowAdditionalTaxSettings" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                <span class="font-weight-medium body-1">Agregar otra actividad económica</span>
              </v-btn>
            </div>
            <v-divider class="my-4" />

            <div class="px-5 pt-1">
              <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
                Dirección
                <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
              </v-toolbar-title>

              <div v-for="(d, i) in additionalAddress" :key="i">
                <div class="py-1 d-flex justify-space-between">
                  <div class="col-7 pa-0">
                    <span class="fontBody--text body-1">Dirección</span>
                    <v-text-field v-model="$v.additionalAddress.$each[i].address.$model" :error="$v.additionalAddress.$each[i].address.$error" outlined required single-line dense maxlength="64" hide-details />
                  </div>
                  <div class="col-4 py-0 px-1" style="max-width: 360px;">
                    <span class="fontBody--text body-1">{{$t('generals.Comuna')}}</span>
                    <v-select v-model="d.state" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                      <template v-slot:item="data">
                        <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-1 pa-0" style="max-width: 30px;">
                    <v-btn class="mt-4 ml-n2" v-if="i >= 1 || additionalAddress.length > 1" @click="handlerDelete(d.id, 'extra_addresses', 'modal'), additionalAddress.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                  </div>
                </div>
              </div>
              <v-btn class="mt-1" v-if="additionalAddress.length < 10" @click.prevent="addRowAdditionalAddress" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                <span class="font-weight-medium body-1">Agregar otra dirección</span>
              </v-btn>
            </div>
            <v-divider class="my-4" />

            <div class="px-5 pt-1">
              <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-medium">
                Contactos
                <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
              </v-toolbar-title>

              <div v-for="(d, i) in contacts" :key="i">
                <div class="py-1 d-flex justify-space-between">
                  <div class="col-4 pa-0">
                    <span class="fontBody--text body-1">Nombre</span>
                    <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                  </div>
                  <div class="col-4 py-0 px-1">
                    <span class="fontBody--text body-1">Email</span>
                    <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                  </div>
                  <div class="col-3 pa-0" style="max-width: 290px;">
                    <span class="fontBody--text body-1">Teléfono</span>
                    <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                  </div>
                  <div class="col-1 pa-0" style="max-width: 30px;">
                    <v-btn class="mt-5 ml-n2" v-if="i >= 1 || contacts.length > 1" @click="handlerDelete(d.id, 'contacts', 'modal'), contacts.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                  </div>
                </div>
              </div>
              <v-btn class="mt-1" v-if="contacts.length < 10" @click.prevent="addRowContacts" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                <span class="font-weight-medium body-1">Agregar otro</span>
              </v-btn>
            </div>
          <!-- </template> -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogCustomer=false">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.customers.createLoader" @click="patchCustomer">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal update customer -->

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">Eliminar {{ translateDelete[currentActionDestroy.key] }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">¿Estás seguro de continuar? Esta acción no se puede deshacer</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroy" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->
  </v-row>
  </template>

  <script>
    import { mapState } from 'vuex'
    import cloneDeep from 'lodash/cloneDeep'
    import VEmptyState from '@/components/commons/VEmptyState'
    import MiniHeader from '@/components/commons/MiniHeader'
    import { required, email, url, maxLength, minLength } from 'vuelidate/lib/validators'
    import ChipStatus from '@/components/commons/ChipStatus'
    import VModal from '@/components/commons/VPanel'
    import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
    import RenderPdf from '@/components/files/RenderPdf'
    import DocumentsList from '@/modules/documents/components/DocumentsList'
    import GenericViewMixin from '@/mixins/GenericViewMixin'
    import RenderViewMixin from '@/mixins/RenderViewMixin'
    import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
    // import DocumentTypes from '@/collections/documentTypes'

    export default {
      mixins: [
        GenericViewMixin,
        RenderViewMixin,
        TaxAgencyViewMixin
      ],
      components: {
        ChipStatus,
        VModal,
        MiniHeader,
        SkeletonDetail,
        RenderPdf,
        DocumentsList,
        VEmptyState
      },
      data: () => ({
        currentActionDestroy: {
          id: null,
          key: '',
          type: ''
        },
        confirm: '',
        dialogDelete: false,
        activityPanel: false,
        addressPanel: false,
        contactPanel: false,
        scroll: 0,
        comments: '',
        dialogSwap: false,
        loading: false,
        loadingDetail: false,
        panelIrsSii: [],
        panelComments: false,
        panelEmail: false,
        panelPDF: false,
        panelRelatedDocuments: false,
        isAccept: false,
        dialogConfirm: false,
        currentList: [],
        current: {},
        activeCurrency: {},
        dialogCustomer: false,
        dialogMetadata: false,
        dialogOrgunitCreate: false,
        dialogSource: false,
        editable: {},
        editableMetadata: [{ key: '', value: '' }],
        loaderMetadata: false,
        loaderSource: false,
        source: {
          description: '',
          recipient: ''
        },
        taxSettings: [
          {
          country: 'CL',
          fields: ['cl_activity_description']
          }
        ],
        breadcrumbs: {
          main: 'Emisión',
          children: [
          {
            text: 'Clientes',
            route: 'CustomerListCreate'
          }
          ]
        },
        attrs: {
          boilerplate: true,
          elevation: 0
        },
        msgDelete: {
          contacts: 'El contacto ha sido eliminado',
          extra_addresses: 'La dirección adicional ha sido eliminada',
          business_activities: 'La actividad adicional ha sido eliminada'
        },
        translateDelete: {
          contacts: 'contacto',
          extra_addresses: 'dirección adicional',
          business_activities: 'actividad adicional'
        },
        additionalTaxSettings: [{ description: '' }],
        additionalAddress: [{ address: '', state: '' }],
        contacts: [{ name: '', email: '', phone: '' }],
        additionalTaxSettingsLoading: false,
        additionalAddressLoading: false,
        contactsLoading: false,
        additionalTaxSettingsDeleteList: [],
        contactsDeleteList: [],
        additionalAddressDeleteList: []
    }),
    computed: {
      ...mapState({
        statesList: state => state.base.statesList,
        instance: state => state.customers.customersDetail,
        count: state => state.documents.documentsCount,
        documentsList: state => state.documents.documentsList,
        business_activitiesList: state => state.customers.business_activitiesList,
        extra_addressesList: state => state.customers.extra_addressesList,
        contactsList: state => state.customers.contactsList
      }),
      nameState () {
        return this.$store.getters['base/currentStates']?.find(state => state.id === this.editable.state)?.name ?? this.$store.getters['base/currentStates']?.find(({name}) => name.toLowerCase() === this.editable.state.toLowerCase())?.name ?? ''
      },
      nameStateList () {
        return (item = '') => {
          return this.$store.getters['base/currentStates']?.find(state => (state.id === item))?.name ?? ''
        }
      }
    },
    watch: {
      panelRelatedDocuments (val) {
        if (val === 0) this.getList()
      },
      activityPanel (val) {
        if (val === 0) this.getBusinessActivities()
      },
      addressPanel (val) {
        if (val === 0) this.getExtraAddress()
      },
      contactPanel (val) {
        if (val === 0) this.getContacts()
      }
    },
    created () {
      this.loadingDetail = true
      this.$store.dispatch('customers/RETRIEVE', {
        resource: 'customers',
        id: this.$route.params.id
      })
      .then(() => {
        const customer = cloneDeep(this.instance)

        this.editable = {
          ...customer
        //   ...{ emails: [{ email: '' }] },
        //   ...{ additionalTaxSettings: [{ cl_activity_description: '' }] },
        //   ...{ additionalAddress: [{ address: '', state: '' }] },
        //   ...{ contacts: [{ name: '', email: '', phone: '' }] }
        }
        this.editableMetadata = Object.keys(this.editable.metadata).map((k) => {
          return { key: k, value: this.editable.metadata[k] }
        })
        this.editable.state = this.nameState || this.editable.state
      })
      .finally(() => {
        this.loadingDetail = false
      })
    },
    methods: {
      handlerEdit () {
        this.getContacts('isEdit')
        this.getExtraAddress('isEdit')
        this.getBusinessActivities('isEdit')
        this.dialogCustomer = true
      },
      getContacts (action = '') {
        this.contactsLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/contacts',
           query: {
            customer: this.$route.params.id
          }
        })
        .then(() => {
          if (this.contactsList.length) {
            this.contacts = cloneDeep(this.contactsList)
          }
        })
        .finally(() => {
          this.contactsLoading = action !== 'isEdit' && !this.contactsLoading
        })
      },
      getExtraAddress (action = '') {
        this.additionalAddressLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/extra_addresses',
           query: {
            customer: this.$route.params.id
          }
        })
        .then(() => {
          if (this.extra_addressesList.length) {
            this.additionalAddress = cloneDeep(this.extra_addressesList)
          }
        })
        .finally(() => {
          this.additionalAddressLoading = action !== 'isEdit' && !this.additionalAddressLoading
        })
      },
      getBusinessActivities (action = '') {
        this.additionalTaxSettingsLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/business_activities',
          query: {
            customer: this.$route.params.id
          }
        })
        .then(() => {
          if (this.business_activitiesList.length) {
            this.additionalTaxSettings = cloneDeep(this.business_activitiesList)
          }
        })
        .finally(() => {
          this.additionalTaxSettingsLoading = action !== 'isEdit' && !this.additionalTaxSettingsLoading
        })
      },
      addRowAdditionalTaxSettings () {
        this.additionalTaxSettings.push({
          cl_activity_description: ''
        })
      },
      addRowAdditionalAddress () {
        this.additionalAddress.push({
          address: '',
          state: ''
        })
      },
      addRowContacts () {
        this.contacts.push({
          name: '',
          email: '',
          phone: ''
        })
      },
      async getList () {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            customer: this.instance.id,
            page_size: 6
          }
        })
        this.loading = false
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      patchCustomer () {
        this.$v.editable.$touch()
        if (this.$v.editable.$invalid) return false

        if (this.additionalAddress[0]?.state?.length) {
          this.$v.additionalAddress.$touch()
          if (this.$v.additionalAddress.$invalid) return false
        }
        if (this.contacts[0]?.phone?.length || this.contacts[0]?.name?.length) {
          this.$v.contacts.$touch()
          if (this.$v.contacts.$invalid) return false
        }
        const payload = {
          name: this.editable.name,
          state: this.editable.state,
          address: this.editable.address,
          tax_settings: this.editable.business_activity,
          email: this.editable.email,
          phone: this.editable.phone,
          website: this.editable.website
        }
        // if (!this.instance.country.has_regulation) {
        //   payload.tax_id = this.editable.taxpayer.tax_id
        // }
        this.$store.dispatch('customers/UPDATE', {
          resource: 'customers',
          id: this.$route.params.id,
          payload: payload
        })
        .then(async (response) => {
          await Promise.all(this.additionalTaxSettings.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/business_activities',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.description.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/business_activities',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          await Promise.all(this.additionalAddress.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/extra_addresses',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.address.length || item.state.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/extra_addresses',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          await Promise.all(this.contacts.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/contacts',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.email.length || item.name.length || item.phone.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/contacts',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          this.dialogCustomer = false
          this.$dialog.message.info('El cliente ha sido actualizado')
        })
        .catch(() => {
          this.$dialog.message.error('Error al actualizar el cliente.')
        })
      },
      updateDialogOrgunitCreate (value) {
        this.dialogOrgunitCreate = value
      },
      updateMetadata () {
        this.$v.editableMetadata.$touch()
        if (this.$v.editableMetadata.$invalid) {
          return false
        }
        this.loaderMetadata = true
        const payload = Object.fromEntries(this.editableMetadata.map(({ key, value }) => [key, value]))
        this.$store.dispatch('customers/UPDATE', {
          resource: 'customers',
          id: this.instance.id,
          payload: {
            metadata: payload
          }
        })
        .then(() => {
          this.dialogMetadata = false
          this.$dialog.message.info('El cliente ha sido actualizado')
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loaderMetadata = false
        })
      },
      handlerDelete (id = null, key = '', type = '') {
        this.currentActionDestroy = { id, key, type }
        if (type !== 'modal') this.dialogDelete = true
        else this.destroy()
      },
      destroy () {
        let resource = {}
        if (this.currentActionDestroy.key === 'contacts') resource = 'contacts'
        if (this.currentActionDestroy.key === 'extra_addresses') resource = 'extra_addresses'
        if (this.currentActionDestroy.key === 'business_activities') resource = 'business_activities'

        this.$store.dispatch('customers/DESTROY', {
          resource: `customers/${resource}`,
          id: this.currentActionDestroy.id
        })
        .then(() => {
          if (this.currentActionDestroy.type !== 'modal') {
            if (this.currentActionDestroy.key === 'contacts') this.getContacts()
            if (this.currentActionDestroy.key === 'extra_addresses') this.getExtraAddress()
            if (this.currentActionDestroy.key === 'business_activities') this.getBusinessActivities()

            this.$dialog.message.info(this.msgDelete[this.currentActionDestroy.key])
          }
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data.detail))
        })
        .finally(() => {
          this.dialogDelete = false
          this.confirm = ''
        })
      }
    },
    validations: {
      editable: {
        name: {
          required,
          maxLength: maxLength(100)
        },
        state: {
          required
        },
        address: {
          required,
          maxLength: maxLength(64)
        },
        business_activity: {
          maxLength: maxLength(64)
        },
        taxpayer: {
          tax_id: {
            maxLength: maxLength(32)
          }
        },
        email: {
          email,
          maxLength: maxLength(254)
        },
        phone: {
          maxLength: maxLength(16)
        },
        website: {
          url
        }
      },
      editableMetadata: {
        $each: {
          key: {
            required,
            maxLength: maxLength(32),
            itsRepeated: function (value) {
              if (value) {
                return (this.editableMetadata.filter(d => d.key === value).length === 1)
              }
              return true
            }
          },
          value: {
            required,
            maxLength: maxLength(256)
          }
        },
        minLength: minLength(1),
        maxLength: maxLength(10)
      },
      source: {
        recipient: {
          required,
          email
        },
        description: {
          required,
          maxLength: maxLength(256)
        }
      },
      additionalAddress: {
        $each: {
          address: {
          required,
            maxLength: maxLength(64)
          }
        }
      },
      contacts: {
        $each: {
          email: {
          required,
            email,
            maxLength: maxLength(64)
          }
        }
      }
    }
  }
  </script>